//Fixed Header Scroll
var didScroll;
var lastScrollTop = 0;
var delta = 5;

$(window).scroll(function(event){
    didScroll = true;
});

setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

function hasScrolled() {
    var st = $(this).scrollTop();
    if(Math.abs(lastScrollTop - st) <= delta)
        return;
        
    if (st > lastScrollTop && st>=200){
        $('body').addClass('scroll-up');
    } else {
        if(st + $(window).height() < $(document).height()) {
            $('body').removeClass('scroll-up');
        }
    }
    lastScrollTop = st;
}